import { StateContext } from '@store/store';
import { useContext } from 'react';

export const creditsRange = [0, 1, 2, 3, 5, 10, 20, 30, 60, 120];

export function useAiCredits({
  isYearlyDuration,
  planKey,
}: {
  isYearlyDuration: boolean;
  planKey: string;
}) {
  const globalState = useContext(StateContext);
  const { aiCreditsCounter, addOnSubscriptionPlans } = globalState;

  let aiCreditsPrice = 0;
  if (isYearlyDuration) {
    aiCreditsPrice =
      creditsRange[aiCreditsCounter[planKey]] *
      (addOnSubscriptionPlans.generative.yearly.amount / 12);
  } else {
    aiCreditsPrice =
      creditsRange[aiCreditsCounter[planKey]] *
      addOnSubscriptionPlans.generative.monthly.amount;
  }
  return {
    aiCredits: creditsRange[aiCreditsCounter[planKey]] * 60,
    aiCreditsBoost: creditsRange[aiCreditsCounter[planKey]],
    aiCreditsPrice,
  };
}
export const getSeatsFromCounter = (counter: number) => {
  return creditsRange[counter];
};

export const getAiCreditsFromCounter = (counter: number) => {
  return creditsRange[counter] * 60;
};

export const getAiCreditsPrice = ({
  planKey,
  isYearlyDuration,
  globalState,
}: {
  planKey: string;
  isYearlyDuration: boolean;
  globalState: any;
}) => {
  const { aiCreditsCounter, addOnSubscriptionPlans } = globalState;

  let aiCreditsPrice = 0;
  if (isYearlyDuration) {
    aiCreditsPrice =
      creditsRange[aiCreditsCounter[planKey]] *
      (addOnSubscriptionPlans.generative.yearly.amount / 12);
  } else {
    aiCreditsPrice =
      creditsRange[aiCreditsCounter[planKey]] *
      addOnSubscriptionPlans.generative.monthly.amount;
  }
  return aiCreditsPrice;
};

export const convertGenerativeCreditsToTime = (credits: number): string => {
  if (typeof credits !== 'number') {
    return 'No';
  }

  if (credits < 199) {
    return `${credits} sec`;
  }

  return `${Math.floor(credits / 60)} min`;
};
